import React from 'react'
import { motion } from 'framer-motion'
import { images } from '../../constants'

import './Footer.scss'


const Footer = () => {
  return (
    <div className='app__footer app__flex'>
        <motion.div
            whileInView={{ y: [-50, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <div className="app__footer-logo">
                <img src={ images.logo } alt="HHCDesign" />
            </div>
            <div className="copyright">
                <p className="p-text">&copy; {(new Date().getFullYear())} HHCDesign</p>
                <p className="p-text">Todos los derechos reservados</p>
            </div>
          </motion.div>
        
    </div>
    
  )
}

export default Footer