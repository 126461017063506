import React from 'react';

import { About, Contact, Footer, Header, Skills, Resume, /* Testimonial, */ Work } from './container';
import { Navbar } from './components';
import './App.scss';

const App = () => (
  <div className="app">
    <Navbar />
    <Header />
    <About />
    <Work />
    <Skills />
    <Resume />
    {/* <Testimonial /> */}
    <Contact />
    <Footer />
  </div>
);

export default App;
