import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <a href="https://www.linkedin.com/in/hyavary-hernandez-07075828/" target="_blank" rel="noreferrer">
      <div>
        <FaLinkedinIn />
      </div>
    </a>
    {/* <div>
      <FaFacebookF />
    </div>
    <div>
      <BsInstagram />
    </div> */}
  </div>
);

export default SocialMedia;
