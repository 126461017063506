import React, { useState, useEffect } from 'react'
import { AppWrap, MotionWrap } from '../../wrapper'
import { motion } from 'framer-motion'
import { client } from '../../client'
import { AiOutlineEdit, AiOutlineDownload } from 'react-icons/ai'
import { images } from '../../constants'

import './Resume.scss'

const Resume = () => {
    const [resume, setResume] = useState([])

    useEffect(() => {
        const query = '*[_type == "resume"] { name, "resumeUrl": resume.asset->url }'
        
        client.fetch(query).then((data) => {
            setResume(data)
        })
    }, [])
    

  return (
    <>
        <h2 className="head-text">Estoy disponible como <span>Freelancer</span></h2>
        <div className='app__resume-container'>
            <motion.div 
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className="app__resume-img app__flex"
                key='hireme'
            >
                <img src={ images.hireme } alt="Contratame" />
            </motion.div>
            <motion.div 
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className="app__resume-item app__flex"
                key='resum.name'
            >
                <div className='app__resume-button'>
                    <a href='#contacto' rel="noreferrer"><AiOutlineEdit /> Contrátame</a>
                </div>
            </motion.div>
            {resume.map((resum) => (
                <motion.div 
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="app__resume-item app__flex"
                    key={resum.name}
                >
                    <div className='app__resume-button'>
                        <a href={`${resum.resumeUrl}?dl=CV-Hyavary-Hernandez.pdf`} target="_blank" rel="noreferrer"><AiOutlineDownload /> Descarga mi Curriculum</a>
                    </div>
                </motion.div>
            ))}
        </div>
    </>
  )
}

export default AppWrap(
    MotionWrap(Resume, 'app__resume'),
    'contratame',
    'app__primarybg',
  );